import styled from "styled-components"
import { Colors } from "@flow/style"
import React, { useState, useEffect } from "react"
import TextButton from "../common/TextButton"
import SpecialTerm from "./SpecialTerm"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import lodash from "lodash"
import { getCurrency } from "../../util/getCurrency"

/**
 * Creates a component for editing a delivery.
 * @param {object} props
 * @param {InFlow.Delivery} props.delivery The delivery to be used
 * @param {function} props.onChange Function called whenever a delivery compentent is changed
 * @param {function} props.t Translation
 * @param {{label: string, value: string}[]} props.specialTermTags The special term tags
 * @param {string} props.deliveryId The id of the delivery
 * @param {string} props.editingId The id of the delivery being edited
 * @param {function} props.setEditingId Function to set the id of the delivery being edited
 * @param {boolean} props.readOnly If the component is read only
 * @param {boolean} props.isMaintenance If the delivery is maintenance
 * @param {string} props.category The category of the application
 * @param {InFlow.DeliveryItems} [props.deliveries] The deliveries in the application
 * @param {function} [props.setDeliverySpecialTerms] The special terms of the delivery
 * @returns {JSX.Element}
 */
const DeliveryComponent = ({
  delivery,
  onChange,
  t,
  specialTermTags,
  deliveryId,
  editingId = null,
  setEditingId = null,
  readOnly = false,
  isMaintenance,
  category,
  deliveries,
  setDeliverySpecialTerms,
}) => {
  /**
   * When clicking on the "show-special-terms-button-text"-button (+ vis særvilkår)
   */

  /** @type {InFlow.SpecialTerms} */
  const specialTerms = delivery.specialTerms
  const {
    userAdded,
    mandatory,
    specialTermsInEdit,
    specialTermsSortedByPriority,
  } = specialTerms
  const onAddSpecialTerm = () => {
    // Adding a term to be filled in by the SpecialTerm component
    const newTerm = {
      termText: "",
      disabled: false,
    }
    const newDev = lodash.cloneDeep(delivery)
    newDev.specialTerms.specialTermsInEdit = newTerm
    onChange({ deliveryId, data: newDev })

    setEditingId(deliveryId)
  }

  const handleAddMultipleSpecialTerms = (
    deliveryIdsToAdd,
    finishedSpecialTerm
  ) => {
    deliveryIdsToAdd.forEach((deliveryId) => {
      const cloned = lodash.cloneDeep(deliveries[deliveryId])
      cloned.id = deliveryId
      addEditTermNew(cloned, finishedSpecialTerm)
      setDeliveryIdsToAdd([])
    })
  }

  const addEditTermNew = (deliveryToUpdate, finishedSpecialTerm) => {
    const newDev = lodash.cloneDeep(deliveryToUpdate)
    newDev.specialTerms.userAdded.push(finishedSpecialTerm)
    delete newDev.specialTerms.specialTermsInEdit

    const deliverySortedSpecialTerms =
      newDev.specialTerms?.specialTermsSortedByPriority ?? []

    // Update sortedSpecialTerms
    const updatedSortedSpecialTerms = [
      ...(deliverySortedSpecialTerms ?? []),
      {
        ...finishedSpecialTerm,
        userAdded: true,
        priority: deliverySortedSpecialTerms.length,
      },
    ]

    // Ensure newDev.specialTerms.userAdded is in sync with updatedSortedSpecialTerms
    newDev.specialTerms.userAdded = updatedSortedSpecialTerms.filter(
      (term) => term.userAdded
    )

    if (delivery.id === deliveryToUpdate.id) {
      setSortedSpecialTerms(updatedSortedSpecialTerms)
    }
    newDev.specialTerms.specialTermsSortedByPriority = updatedSortedSpecialTerms

    if (setDeliverySpecialTerms) {
      setDeliverySpecialTerms((prevDeliverySpecialTerms) => {
        return [
          ...prevDeliverySpecialTerms.filter(
            (dev) => dev.deliveryId && dev.deliveryId !== newDev.id
          ),
          {
            deliveryId: newDev.id,
            specialTerms: newDev.specialTerms,
          },
        ]
      })
    }
    onChange({
      deliveryId,
      data: newDev,
    })
    setEditingId(null)
  }

  const addEditTerm = (finishedSpecialTerm) => {
    const currentDeliveryIdsToAdd = [...deliveryIdsToAdd, deliveryId]

    if (currentDeliveryIdsToAdd.length > 0) {
      handleAddMultipleSpecialTerms(
        new Set([...deliveryIdsToAdd, deliveryId]),
        finishedSpecialTerm
      )
    }
  }

  const onDelete = (termToRemove) => {
    const newDev = lodash.cloneDeep(delivery)
    newDev.specialTerms.userAdded = delivery.specialTerms.userAdded.filter(
      (term) => term !== termToRemove
    )

    const newSortedSpecialTerms = sortedSpecialTerms.filter(
      (term) => term !== termToRemove
    )

    // remove duplicates from userAdded that are not in sortedSpecialTerms
    newDev.specialTerms.userAdded = newDev.specialTerms.userAdded.filter(
      (term) =>
        sortedSpecialTerms.find((sortedTerm) => sortedTerm.termText === term)
    )
    // Why is this not deleting correctly?
    newDev.specialTerms.specialTermsSortedByPriority = newSortedSpecialTerms
    setSortedSpecialTerms((prevSortedSpecialTerms) =>
      prevSortedSpecialTerms.filter((term) => term !== termToRemove)
    )
    onChange({ deliveryId, data: newDev })
  }

  const onCancel = () => {
    const newDev = lodash.cloneDeep(delivery)
    newDev.specialTerms.specialTermsInEdit = null
    onChange({ deliveryId, data: newDev })

    setEditingId(null)
  }

  const currency = getCurrency(category)
  const currencySymbol = currency === "NOK" ? "kr" : currency
  const [draggedItem, setDraggedItem] = useState(null)
  const [dragDirection, setDragDirection] = useState(null)
  const [deliveryIdsToAdd, setDeliveryIdsToAdd] = useState([])

  /**
   * When dragging a special term
   * @param {React.DragEvent<HTMLDivElement>} e
   * @param {InFlow.SpecialTerm} term
   * @returns {void}
   */
  const handleDragStart = (e, term, termIndex) => {
    setDraggedItem({
      ...term,
      termIndex,
    })
    e.dataTransfer.effectAllowed = "move"

    // Clone the element being dragged
    /** @type {HTMLElement} */
    const originalElement = e.target
    /** @type {HTMLElement} */
    const clone = originalElement.cloneNode(true)

    // Style the cloned element
    clone.style.backgroundColor = Colors.Grey3
    clone.style.width = "35vw"

    // Append it to the document body (required for drag image)
    document.body.appendChild(clone)
    // Set the cloned element as the drag image
    e.dataTransfer.setDragImage(clone, 75, 75)

    // Remove the cloned element when the drag ends
    e.target.addEventListener("dragend", () => {
      clone.remove()
    })
  }

  /**
   * When dragging over a special term
   * @param {React.DragEvent<HTMLDivElement} e
   * @returns {void}
   */
  const handleDragOver = (e, index) => {
    e.preventDefault()
    setDragOverIndex(index)

    if (draggedItem) {
      // Implement your logic to handle the drag over event
      const direction = index > draggedItem.termIndex ? "down" : "up"
      setDragDirection(direction)
    }

    e.dataTransfer.dropEffect = "move"
  }

  const mandatoryTermsWithPriority = getMandatoryTermsWithPriority(
    mandatory,
    userAdded
  )
  /** @type {SpecialTerm[]} */
  const sortedItemsWithPriority = specialTermsSortedByPriority
    ? specialTermsSortedByPriority
    : sortItemsWithPriority(mandatoryTermsWithPriority, userAdded)

  const [sortedSpecialTerms, setSortedSpecialTerms] = useState(
    sortedItemsWithPriority
  )

  useEffect(() => {
    onChange({
      deliveryId,
      data: {
        ...delivery,
        specialTerms: {
          ...delivery.specialTerms,
          specialTermsSortedByPriority: sortedSpecialTerms,
        },
      },
    })
  }, [])

  useEffect(() => {
    const sortedSpecialTermsForDelivery =
      delivery.specialTerms.specialTermsSortedByPriority
    if (sortedSpecialTermsForDelivery) {
      setSortedSpecialTerms(sortedSpecialTermsForDelivery)
    }
  }, [delivery])

  const [dragOverIndex, setDragOverIndex] = useState(null)

  /**
   * When dropping a special term
   * @param {React.DragEvent<HTMLDivElement} e
   * @param {number} targetTermIndex
   * @returns {void}
   */
  const handleDrop = (e, targetTermIndex) => {
    e.preventDefault()

    if (!draggedItem) {
      return
    }
    const draggedItemIndex = sortedSpecialTerms.findIndex(
      (term) => term.termText === draggedItem.termText
    )

    // Create a new array without the dragged item
    const newSortedTerms = sortedSpecialTerms.filter(
      (term, index) => index !== draggedItemIndex
    )
    const isDraggingUp = draggedItemIndex > targetTermIndex

    if (isDraggingUp) {
      // We want to add borderTop instead of borderBottom
      //setDragOverIndex(targetTermIndex - 1)
      newSortedTerms.splice(targetTermIndex, 0, draggedItem)
    }
    // Insert the dragged item at the target index
    // If the dragged item is at start, the index should be 0
    else if (targetTermIndex === 1 && draggedItemIndex === 0) {
      //newSortedTerms.unshift(draggedItem)
      //newSortedTerms.splice(0, 0, draggedItem)
      newSortedTerms.splice(targetTermIndex, 0, draggedItem)
    }
    // If the dragged item is at end, the index should be list.length - 1
    else if (targetTermIndex === sortedSpecialTerms.length - 1) {
      newSortedTerms.push(draggedItem)
    } else {
      newSortedTerms.splice(targetTermIndex, 0, draggedItem)
    }
    const newSortedTermsWithPriority = newSortedTerms.map((term, index) => ({
      ...term,
      priority: index,
    }))

    // Update the state with the new order
    setSortedSpecialTerms(newSortedTermsWithPriority)
    setDraggedItem(null)
    const draggedElement = document.querySelector(".dragging")
    if (draggedElement) {
      draggedElement.classList.remove("dragging")
      draggedElement.style.border = "none"
      draggedElement.style.backgroundColor = "grey"
    }
    const newDev = lodash.cloneDeep(delivery)
    newDev.specialTerms.specialTermsSortedByPriority =
      newSortedTermsWithPriority
    // Update mandatory terms with priority and userAdded terms with priority
    newDev.specialTerms.mandatory = newSortedTermsWithPriority.filter(
      (term) => !term.userAdded
    )
    newDev.specialTerms.userAdded = newSortedTermsWithPriority.filter(
      (term) => term.userAdded
    )

    onChange({ deliveryId, data: newDev })
  }

  return (
    <>
      <Box>
        <ProductTitle>
          {t(delivery.productName)}{" "}
          {isMaintenance
            ? ""
            : `${currencySymbol} ${addThousandSeparator(delivery.amount)}`}
        </ProductTitle>
        {sortedSpecialTerms.map((term, i) => {
          return (
            <DraggableItem
              key={i}
              readOnly={readOnly}
              draggable={!readOnly}
              onDragStart={(e) => handleDragStart(e, term, i)}
              onDragOver={!readOnly ? (e) => handleDragOver(e, i) : undefined}
              onDrop={(e) => handleDrop(e, i)}
              draggedItem={draggedItem}
              dragDirection={dragDirection}
              dragOverIndex={dragOverIndex}
              i={i}
            >
              <SpecialTerm
                specialTerm={term}
                disabled={true}
                deletable={term.userAdded && !readOnly}
                onDelete={
                  term.userAdded && !readOnly ? () => onDelete(term) : undefined
                }
                categories={specialTermTags}
                t={t}
              />
            </DraggableItem>
          )
        })}

        {specialTermsInEdit && (
          <div
            draggable={!readOnly}
            onDragStart={(e) => handleDragStart(e, deliveryId)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, deliveryId)}
          >
            <SpecialTerm
              key={"S"}
              specialTerm={specialTermsInEdit}
              onDelete={onDelete}
              onAdd={addEditTerm}
              onCancel={onCancel}
              categories={specialTermTags}
              t={t}
              deliveries={deliveries}
              deliveryIdsToAdd={deliveryIdsToAdd}
              setDeliveryIdsToAdd={setDeliveryIdsToAdd}
              currentDeliveryId={deliveryId}
            />
          </div>
        )}
      </Box>
      {!specialTermsInEdit && !readOnly && !editingId && (
        <TextButton onClick={onAddSpecialTerm}>
          {t("show-special-terms-button-text")}
        </TextButton>
      )}
    </>
  )
}

/**
 * @typedef {object} DraggableItemProps
 * @property {boolean} readOnly
 * @property {object} draggedItem
 * @property {string} dragDirection
 * @property {number} dragOverIndex
 * @property {number} i
 */

export default DeliveryComponent

const Box = styled.div`
  color: black;
  background-color: ${Colors.Grey4};
  padding: 10px;
  border-radius: 5px;
`
const ProductTitle = styled.div`
  color: black;
  font-size: 15px;
`

/**
 * @type {import('styled-components').StyledComponent<'div', any, DraggableItemProps, never>}
 */
const DraggableItem = styled.div`
  padding: 10px;
  :hover {
    cursor: ${({ readOnly }) => (readOnly ? "default" : "grab")};
    background-color: ${Colors.Grey3};
  }
  transition: transform 0.2s ease;

  border-bottom: ${({ draggedItem, dragDirection, dragOverIndex, i }) =>
    draggedItem && dragDirection === "down" && dragOverIndex === i
      ? `0.15vw solid ${Colors.Sea}`
      : "none"};
  border-top: ${({ draggedItem, dragDirection, dragOverIndex, i }) =>
    draggedItem && dragDirection === "up" && dragOverIndex === i
      ? `0.15vw solid ${Colors.Sea}`
      : "none"};
  margin-top: ${({ dragOverIndex, i }) =>
    dragOverIndex === i ? "0.8vw" : "0px"};
`

const sortItemsWithPriority = (mandatoryTermsWithPriority, userAdded) => {
  return [
    ...(mandatoryTermsWithPriority
      ? mandatoryTermsWithPriority.map((term) => ({
          ...term,
          userAdded: false,
        }))
      : []),
    ...(userAdded
      ? userAdded.map((term) => ({ ...term, userAdded: true }))
      : []),
  ]
    .map((term, index) => ({
      ...term,
      priority: term.priority ?? index,
    }))
    .sort((a, b) => a.priority - b.priority)
}

const getMandatoryTermsWithPriority = (mandatory, userAdded) => {
  const userAddedPriorities = userAdded.map((term) => term.priority)
  // Sort the priorities
  userAddedPriorities.sort((a, b) => a - b)
  // Find the smallest missing positive integer
  let mandatoryPriority = 0
  for (let i = 0; i < userAddedPriorities.length; i++) {
    if (userAddedPriorities[i] === mandatoryPriority) {
      mandatoryPriority++
    } else if (userAddedPriorities[i] > mandatoryPriority) {
      break
    }
  }
  const mandatoryTermsWithPriority =
    mandatory?.length > 0
      ? mandatory.map((term, index) => ({
          ...term,
          priority: mandatoryPriority + index,
        }))
      : []

  return mandatoryTermsWithPriority
}
