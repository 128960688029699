import styled from "styled-components"
import { Colors } from "@flow/style"
import React, { useEffect, useState } from "react"
import DeliveryComponent from "./DeliveryComponent"
import axios from "axios"
import { Spinner, Icons } from "@flow/icons"
import isTesting from "../../util/isTesting"
import { ProjectColors } from "../common/style/ProjectColors"

/**
 * The special term tab
 * @param {object} props The properties of the SpecialTerm tab
 * @param {InFlow.DeliveryItems} props.deliveries The deliveries in the application
 * @param {function} props.onChange Function that adds the changes to state
 * @param {function} props.t Translation
 * @param {boolean} [props.readOnly=false] If the tab is read
 * @returns {JSX.Element}
 */
const SpecialTermsTab = ({
  deliveries,
  onChange,
  t,
  readOnly = false,
  category,
}) => {
  const SPECIAL_TERMS_LINK =
    "https://innovationnorway.sharepoint.com/sites/Finansieringshandboken#4.17-s%C3%A6rvilk%C3%A5r"

  useEffect(() => {
    if (isTesting()) return undefined
    const caller = async () => {
      const specialTermTagsRequest = async () => {
        try {
          setGettingSpecialTerms(true)
          return await axios.get("/api/special-term-tags")
        } catch (error) {
          console.error("Failed to get special term tags")
          setCheckSpecialTermsError(true)
        }
      }
      // Initializing
      const getSpecialTermTags = await specialTermTagsRequest()
      setGettingSpecialTerms(false)

      setSpecialTermTags(
        getSpecialTermTags.data.map((tag) => {
          return {
            value: tag.slice(0, 2),
            label: tag,
          }
        })
      )
    }
    caller()
  }, [])

  const [gettingSpecialTerms, setGettingSpecialTerms] = useState(false)
  const [checkSpecialTermsError, setCheckSpecialTermsError] = useState(false)
  const [specialTermsTags, setSpecialTermTags] = useState(false)
  const [editingId, setEditingId] = useState(null)

  const initialSpecialTermsDeliveries = Object.keys(deliveries).reduce(
    (acc, deliveryId) => {
      acc.push({
        deliveryId,
        specialTerms: deliveries[deliveryId].specialTerms,
      })
      return acc
    },
    []
  )

  const [deliveriesSpecialTerms, setDeliveriesSpecialTerms] = useState(
    initialSpecialTermsDeliveries
  )

  useEffect(() => {
    if (deliveriesSpecialTerms.length > 0) {
      deliveriesSpecialTerms.forEach((delivery) => {
        if (!delivery?.deliveryId) return
        onChange({
          deliveryId: delivery.deliveryId,
          data: {
            ...deliveries[delivery.deliveryId],
            specialTerms: delivery.specialTerms,
          },
        })
      })
    }
  }, [deliveriesSpecialTerms])

  const isMaintenance = Boolean(deliveries?.vedlikehold)
  if (checkSpecialTermsError) {
    return (
      <LoadingPage>
        <Icons.ErrorCircle />
        <div>
          {t("could-not-fetch-specialterms")} <br /> {t("close-and-try-again")}
        </div>
      </LoadingPage>
    )
  }

  return (
    <ContentWrapper>
      {gettingSpecialTerms ? (
        <LoadingPage>
          <Spinner size={50} />
          <div>{t("wait-specialterms")}</div>
        </LoadingPage>
      ) : (
        <>
          <StyledLinkWrapper>
            <StyledLink href={SPECIAL_TERMS_LINK}>
              {t("financialGuideLink")}
            </StyledLink>
          </StyledLinkWrapper>
          <Title>{t("PRODUCT")}</Title>
          {Object.keys(deliveries)?.map((deliveryId, i) => (
            <React.Fragment key={i}>
              <DeliveryComponent
                delivery={deliveries[deliveryId]}
                deliveryId={deliveryId}
                t={t}
                onChange={onChange}
                specialTermTags={specialTermsTags}
                readOnly={readOnly}
                editingId={editingId}
                setEditingId={setEditingId}
                isMaintenance={isMaintenance}
                category={category}
                deliveries={deliveries}
                setDeliverySpecialTerms={setDeliveriesSpecialTerms}
              />
              <Divider />
            </React.Fragment>
          ))}
        </>
      )}
    </ContentWrapper>
  )
}

export default SpecialTermsTab

const ContentWrapper = styled.div`
  margin: 15px;
`
const Title = styled.div`
  color: ${Colors.Grey1};
  margin: 0 0 10px 5px;
`
const Divider = styled.div`
  margin: 15px;
`
const LoadingPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin-left: 10px;
  }
`
const StyledLinkWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 10px;
  margin-bottom: 10px;
`
const StyledLink = styled.a`
  text-decoration: underline;
  color: ${ProjectColors.Blue};
  cursor: pointer;
`
